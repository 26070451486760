import { BASEPATH, EVENT_TYPE } from './constants'
import { isFunction } from './helper/util'

// console.log(process.env.DSM_VERSION)
let cdnHost =
  location.host.indexOf('.17173') !== -1 ? 'ue.17173cdn.com' : 'ue.1ycdn.com'

if (window.DSM_IS_HUB === 'true') {
  cdnHost = 'uehub.1ycdn.com'
}

const isDev = !process.env.NODE_ENV
const baseDir = isDev
  ? ''
  : `https://${cdnHost}/${BASEPATH}/${process.env.NODE_ENV}/js`

let dsmBeginLoad = false
function loadJS(src, fn) {
  let scriptEle = document.createElement('script')

  scriptEle.setAttribute('src', src)
  scriptEle.setAttribute('type', 'text/javascript')
  // scriptEle.setAttribute('async', async)

  document.head.appendChild(scriptEle)

  scriptEle.addEventListener('load', () => {
    fn && fn()
  })

  scriptEle.addEventListener('error', (ev) => {
    console.log('Error on loading file', ev)
  })
}

const funs = []
const loadSourcs = function (fn) {
  loadJS(`${baseDir}/config.js?` + 1 * new Date(), function () {
    // loadJS(`https://ue.1ycdn.com/a/lib/slimemolds/${dsm.VERSION}`)
    let dsmDir = isDev ? '' : baseDir + `/${window.DSM_VERSION}`
    loadJS(`${dsmDir}/dsm.js`, function () {
      // loadJS(`https://ue.1ycdn.com/a/lib/slimemolds/${dsm.VERSION}`)
      fn && fn()
    })
  })
}

const callFns = function () {
  while (funs.length) {
    funs.shift()()
  }
}

/**
 * 
 * @namespace dsm
 */
const dsm = {
  config: {},
  E: EVENT_TYPE,
  setConfig(config) {
    dsm.config = config
    dsm.ready()
  },
  ready(fn) {
    if (fn) funs.push(fn)
    if (window.dsm._inited === true) {
      callFns()
    } else if (dsmBeginLoad === false) {
      dsmBeginLoad = true
      loadSourcs(callFns)
    }
    // window.dsm ? fn() : loadSourcs(fn)
  },
  extends(obj) {
    const keys = Object.keys(obj)
    keys.forEach((key) => (window.dsm[key] = obj[key]))
  },
  /**
   * 返回一个与输入对象具有相同成员的代理对象, 监控输入对象内方法(不会递归,请使用简单对象)异常并发送异常统计.
   * @summary 监控对象方法异常
   * 
   * @param {Object} obj 要监控的对象
   * @returns {Object} 代理对象
   * @example
   const people = {
  say(){
    console.log('say')
  },
  run(){
    console.log('run')
  }
}

const peopleProxy = dsm.objectGuard(people)
peopleProxy.say()
peopleProxy.run()
   */
  objectGuard(obj) {
    const keys = Object.keys(obj)
    const proxyObj = {}
    keys.forEach((key) => {
      const value = obj[key]
      if (isFunction(value)) {
        proxyObj[key] = function (...args) {
          // console.log('call:', key, args)
          try {
            const callResult = value.bind(proxyObj)(...args)
            if (callResult && callResult.catch) {
              callResult.catch((error) => {
                error.name = key
                dsm.call('sendJsErrorTrack', error, 2)
              })
            }
            return callResult
          } catch (error) {
            // console.log('error')
            error.name = key
            dsm.call('sendJsErrorTrack', error, 2)
            throw error
          }
        }
      } else {
        proxyObj[key] = value
      }
    })
    return proxyObj
  },
  /**
   * 调用dsm下指定名称和参数的统计组件方法. 详见 {@tutorial dsm} 
   * @summary 调用指定方法.
   * 
   * @param {String} apiName 统计API名称,详见[API列表]{@link dsm}
   * @param {Object} params 接口参数
   * @example
   dsm.call('sendAjaxTrack',{
  name:'删除链接',
  apiUrl:'https://api.17173.com/favorites/v1/delete',
  success:true,
  //msg:'验证码错误',
  //error: '未将对象引用设置到对象实例',
})
   */
  call(...args) {
    if (window.dsm._inited === true) {
      window.dsm[args[0]](...args.slice(1))
    } else {
      funs.push(() => {
        window.dsm[args[0]](...args.slice(1))
      })
    }
  },
}
window.dsm = dsm